import * as React from 'react';

import {
    graphql, HeadProps
} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import animationJson from '../../assets/animations/landingmotion.json';
import { Head as DefaultHead } from '../../components/head/head.component';
import { BlogPostsCarousel } from '../../components/shared/blog-posts-carousel/blog-posts-carousel.component';
import { CustomHeader } from '../../components/shared/custom-header/custom-header.component';
import { LottieAnimation } from '../../components/shared/lottie-animation/lottie-animation.component';
import { TitleBlock } from '../../components/shared/title-block/title-block.component';
import { seo } from '../../config/seo-config';
import { GatsbyStrapiPictureField } from '../../models/cms-common';
import {
    buildGatsbyStrapiImage,
    isUndOrEmpty
} from '../../utils';
import * as styles from './typeOffre.module.css';

export const query = graphql`
    query TypeOffreQuery($slug: String!) {
        strapiTypeOffre(slug: { eq: $slug }) {
            slug
            displayName
            description
            imageBlocks {
                subtitle
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            imageBlocks2 {
                title
                subtitle
            }
        }

        allStrapiArticle(limit: 10, sort: { order: DESC, fields: published_at }) {
            nodes {
                id
                slug
                title
                author
                subtitle
                bannerPicture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                category {
                    id
                    slug
                    color
                }
                tags {
                    id
                    slug
                }
            }
        }

        strapiNosExpertises {
            expertiseDomains {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                ctaLink
                ctaText
            }
        }
    }
`;

interface ExpertiseDomainProps {
    data: {
        strapiTypeOffre: {
            slug: string;
            displayName: string;
            description: string;

            imageBlocks: {
                subtitle: string;
                picture: GatsbyStrapiPictureField;
            }[];
            imageBlocks2: {
                title: string;
                subtitle: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            }[];
        };

        allStrapiArticle: {
            nodes: {
                id: string;
                slug: string;
                title: string;
                subtitle: string;
                bannerPicture: GatsbyStrapiPictureField;
                category: {
                    id: string;
                    slug: string;
                };
                tags: {
                    id: string;
                    slug: string;
                }[];
            }[];
        };
    };
}

const OffrePage: React.FC<ExpertiseDomainProps> = ({ data }: ExpertiseDomainProps) => {
    const title = data.strapiTypeOffre.displayName;
    const description = data.strapiTypeOffre.description;

    const recentBlogPostsTitle = 'Nos articles récents';
    const recentBlogPostsSubtitle = 'Nos articles récents sous-titre';
    const recentBlogPosts = data.allStrapiArticle.nodes;

    const imageBlocks = data.strapiTypeOffre.imageBlocks;
    const imageBlocks2 = data.strapiTypeOffre.imageBlocks2;

    return (
        <>
            <CustomHeader transparent={false} dark={true} backgroundColor='var(--primary-blue)' />
            <div className={`d-flex flex-column align-items-center justify-content-start ${styles.page}`}>
                <div className='d-flex flex-column flex-xl-row'>
                    <div className={`col-12 col-xl-7 d-flex flex-column justify-content-center
                        align-items-center align-items-xl-start ${styles.blockA}`}>
                        <div>
                            <TitleBlock
                                className={`text-uppercase ${styles.offreTitle}`}
                                justifyContent='justify-content-center'>
                                {title}
                                <div className={`${styles.bordered} my-4 w-50`}></div>
                            </TitleBlock>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="text-center text-xl-left m-2">
                                {
                                    !isUndOrEmpty(description) && <ReactMarkdown className={styles.offreDescription}>
                                        {description}
                                    </ReactMarkdown>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-xl-4 d-flex align-items-center justify-content-center'>
                        <LottieAnimation className='m-3' animationJson={animationJson} />
                    </div>
                </div>
                <div className='m-5'>
                    {imageBlocks && imageBlocks.length ? (
                        <div
                            className={`${styles.blockB}
                                d-flex align-items-start justify-content-center`}
                        >
                            <div className="d-flex flex-column flex-xl-row align-items-center align-items-xl-start">
                                {imageBlocks.map((offreItem, index: number) => {
                                    const image = buildGatsbyStrapiImage(offreItem.picture);
                                    return (
                                        <div
                                            key={`${offreItem.subtitle}_${index}`}
                                            className="col m-3 d-flex flex-column align-items-center justify-content-center"
                                        >
                                            <div
                                                className={`d-flex flex-row align-items-center justify-content-center 
                                            ${styles.imageBlocksContainer}`}
                                            >
                                                {image ? (
                                                    <GatsbyImage
                                                        image={image}
                                                        alt={offreItem.subtitle}
                                                        className={`${styles.offreItemImage}`}
                                                    />
                                                ) : null}
                                            </div>
                                            <div
                                                className="col-9 col-xl-12 justify-content-center text-center my-2 my-xl-5">
                                                <span className="display-5 text-white">{offreItem.subtitle}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
                {imageBlocks2 && imageBlocks2.length ? (
                    <div
                        className={`${styles.blockB}
                                d-flex flex-column align-items-start justify-content-center`}
                    >
                        <div className="col-12 justify-content-center text-center my-5">
                            <TitleBlock className="text-white text-uppercase" justifyContent='justify-content-center'>
                                Les phases importantes du projet
                            </TitleBlock>
                        </div>
                        <div className="row container align-self-center align-items-center">
                            {imageBlocks2.map((offreKeySuccess, index: number) => {
                                return (
                                    <div key={`${offreKeySuccess.subtitle}_${index}`} className="col-12 col-xl-6">
                                        <div className={`${styles.offreKeySuccess} d-flex align-items-center m-3`}>
                                            <div className="row col-12 p-0 m-0">
                                                <div
                                                    className={`
                                                ${styles.offreKeySuccessLeftContainer}
                                                col-12 col-xl-5 
                                                d-flex flex-column flex-xl-row 
                                                align-items-center justify-content-center
                                            `}
                                                >
                                                    <div className="text-center mx-2 mt-4 mt-xl-0">
                                                        <span className={styles.counter}>{index + 1}</span>
                                                    </div>
                                                    <div className="text-center text-xl-left mt-3 my-xl-1">
                                                        <h4 className="m-0 p-0">{offreKeySuccess.title}</h4>
                                                    </div>
                                                </div>
                                                <div className={`
                                                col-12 col-xl-7 
                                                d-flex align-items-center 
                                                justify-content-center justify-content-xl-left
                                            `}>
                                                    <div className="m-2 text-center text-xl-left">
                                                        {!isUndOrEmpty(offreKeySuccess.subtitle) && (
                                                            <ReactMarkdown>{offreKeySuccess.subtitle}</ReactMarkdown>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}

                {recentBlogPosts && recentBlogPosts.length ? (
                    <div className={`w-100 ${styles.blockD}`}>
                        <BlogPostsCarousel
                            posts={recentBlogPosts}
                            title={recentBlogPostsTitle}
                            subtitle={recentBlogPostsSubtitle}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default OffrePage;

export const Head = ({ data }: HeadProps<{ strapiTypeOffre: { slug: string } }>) => {
    const { strapiTypeOffre: { slug } } = data;
    const seoData = seo.nosOffres.children ? seo.nosOffres.children[slug] : {};
    return (
        <DefaultHead {...seoData} />
    );
};