// extracted by mini-css-extract-plugin
export var blockA = "typeOffre-module--blockA--905cb";
export var blockB = "typeOffre-module--blockB--3ce4b";
export var blockC = "typeOffre-module--blockC--142ab";
export var blockContainer = "typeOffre-module--blockContainer--9c04c";
export var blockD = "typeOffre-module--blockD--b7011";
export var blockE = "typeOffre-module--blockE--ed48d";
export var bordered = "typeOffre-module--bordered--9110f";
export var counter = "typeOffre-module--counter--67e14";
export var expertiseJobImage = "typeOffre-module--expertiseJobImage--754d6";
export var fullHeight = "typeOffre-module--fullHeight--833a8";
export var imageBlocksContainer = "typeOffre-module--imageBlocksContainer--5215a";
export var offreDescription = "typeOffre-module--offreDescription--686ca";
export var offreItemImage = "typeOffre-module--offreItemImage--44c95";
export var offreKeySuccess = "typeOffre-module--offreKeySuccess--c05c9";
export var offreKeySuccessLeftContainer = "typeOffre-module--offreKeySuccessLeftContainer--44f57";
export var offreTitle = "typeOffre-module--offreTitle--13f2e";
export var page = "typeOffre-module--page--c8b33";
export var title = "typeOffre-module--title--54634";